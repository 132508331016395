.dropdown-list {
    background-color: transparent;
    position: relative;

    
    select {
        // appearance: none;
        border: 0.188rem solid #5464B0 !important;
        border-radius: 0.625rem;
        background-color: transparent !important;
        color: #5464B0 !important;
        appearance: none;
        position: relative;
        padding: 0.375rem 0.75rem;
        padding-right: 2rem;
        display: inline-block;
        box-shadow: none;
        box-sizing: border-box;
        font-weight: 700;
        font-size: 1rem;
        font-family: $font-family-base, Arial, Helvetica, sans-serif;
        text-transform: uppercase;

    }

    button {
        &:hover {
            background: transparent;
            border: 0.188rem solid #5464B0;
            color: #5464B0;
        }
    }
}