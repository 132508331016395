.filter-fields {
    input {
        width: 100%;
        padding: 0.625rem 1rem;
        border-radius: 0.313rem;
        border: 0.063rem solid #E7E7F1;
        box-shadow: 0 0 0.25rem #D8D8D8;
        font-size: 1.063rem;
        font-family: $font-family-base;
        // font-style: italic;
    }
}