.fixed-aside {
    padding-right: 0 !important;

    &::-webkit-scrollbar {
        display: none;
    }

    &.close {

        width: 8%;
        max-width: 128px;

        .aside-top-bar {
            border-radius: 0 1.563rem 1.563rem 0;
            padding: 1.125rem 0;


            .aside-title {
                    display: none;
                }
        }

        .aside-content {
            display: none;
        }

        .toggle.btn {
            padding: 0 !important;
            margin-right: 12px;
        }
    }

    &.open {
        .toggle.btn {
            width: 2.5rem;
            height: 2.5rem;
            background-color: #fff;
        }
    }

    .toggle.btn {
        border: 0;
        outline: none;
        padding: 0.5rem;
        margin-right: 0.625rem;
    }

}

.aside-content {
    background-color: #fff;
    padding: 2px;
    padding-bottom: 0;
    border-bottom-right-radius: 1.25rem;
    margin-bottom: 2.5rem;
    overflow: hidden;

    .title-container {
        border-radius: 0 !important;
        padding: 1.125rem 1.625rem !important;


        &.open-data {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            .title {
                color: #fff;
            }
        }
    }

    .collapsible-wpr {
        border-bottom: 2px solid #fff;

        &:last-child {
            border-bottom-right-radius: 1.25rem;
            overflow: hidden;
        }

        .p-2:not(.title-container) {
            padding: 0 1.625rem !important;
            margin: 1rem 0 2.25rem;

            .table-wrapper {
                border: 1px solid #E7E7F1;
                box-shadow: 0 0 4px rgba(216, 216, 216, 0.5);
                padding: 1.75rem 1rem;
                border-radius: 0.825rem;

                &:not(:first-child) {
                    margin-top: 1.063rem;
                }

                tr:first-child {
                    td {
                        padding-top: 0;
                    }
                }

                tr:last-child {
                    td {
                        padding-bottom: 0;
                    }
                }
            }

            table.table-list {
                width: 100%;

                tr {

                    th {
                        font-size: 1.063rem;
                        padding: 0.75rem 1rem;
                    }

                    th p,
                    td p {
                        margin-bottom: 0;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid #E7E7F1;
                    }

                    td {
                        // font-size: 1.063rem;
                        font-weight: 500;
                        padding: 0.75rem 1rem;

                        p {
                            font-size: 1.063rem;
                        }

                        // &:last-child { padding-left: 0 }

                    }

                    td:nth-child(2) {
                        font-size: 1.0625rem;
                        font-weight: 600;
                        color: #000;
                    }


                }
            }
        }
    }

    .aside-filter-content {

        .filter-list-container {
            height: 65vh;
            overflow-y: scroll;
            margin-top: 1.9rem;
            margin-right: 2rem;

            &::-webkit-scrollbar {
                width: 0.438rem;
                border-radius: 0.625rem;
            }


            &::-webkit-scrollbar-thumb {
                background-color: #5464B0;
                border-radius: 0.625rem;
            }

            &::-webkit-scrollbar-track {
                background-color: #E7E6E6;
                border-radius: 0.625rem;
            }

        }

        .button-container {
            width: 82%;
            margin: 1.5rem auto;
            display: flex;
            justify-content: space-between;

            button {
                flex-basis: 47%;
            }

            .transparent-background {
                background-color: #fff;
                color: #5464B0;
                border-color: #5464B0;
            }

        }

        .filter-fields {
            width: 80%;
            margin: 1.5rem auto;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }


}