.collapsible-wpr {
    .title-container {
        background: rgb(230, 231, 242);

        &.open-data {
            background: #5464B0;
            button {
                img {
                    transform: rotate(180deg);
                    transition: .3s ease-in-out;
                }
            }
        }

        button {
            padding: 0 !important;
            border-radius: 0.625rem;

            img {
                width: 2.5rem;
                height: 2.5rem;
                transform: rotate(0deg);
                transition: .3s ease-in-out;
            }
        }
    }
}