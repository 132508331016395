.input-field-group {
    margin-top: 2rem;
    width: 100%;
    position: relative;

    label {
        font-size: 1.313rem;
        font-family: $font-family-base, arial, sans-serif;
        color: '#252C48';
        font-weight: bold;
        line-height: 1.563rem;
        width: 100%;
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    input {
        width: 100%;
        border: 0;
        background-color: #fff;
        box-sizing: border-box;
        font-size: 1.313rem;
        font-weight: 500;
        color: #000;
        line-height: 1.563rem;
        padding: 1.25rem 1.125rem;
        background-color: #fff !important;
        border: 0.063rem solid #BEBEBE;
        appearance: none;
        box-shadow: none;
        outline: none;
        border-radius: 0.313rem;
    }

    .invalid-feedback {
        width: 100%;
        position: absolute;
        bottom: -1.5rem;
        left: 0;
        color: red;
        display: block;
    }

    ::placeholder {
        color: #BEBEBE;
    }
}