.checkbox-container {
    .filter-fields {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin-bottom: 0;
        }
    }

    input {
        width: 1.625rem;
        height: 1.625rem;
        padding: 0;
        background-color: #fff;
        display: flex;
        align-self: center;

        &:checked {
            content: '';
            width: 1.625rem;
            height: 1.625rem;
            background: url('../../icons/check-icon.png') no-repeat center/cover;
            background-color: #fff;

        }
    }

}