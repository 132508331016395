// Bootstrap
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

$blue: #5464B0 !default;
$secondary: #F6F5F8 !default;

$font-family-base: "Montserrat" !default;

@import './bootstrap.scss';
@import './kendo-grid.scss';

* {
    font-family: $font-family-base, arial, sans-serif;
}

button,
input {
    -webkit-appearance: none;
}

html,
body {
    font-size: 16px;

    @media (max-width: 1440px) {
        font-size: 14px;
    }

    @media (max-width: 1280px) {
        font-size: 12px;
    }
}

// html,
// body {
//     font-size: 0.95vw;

//     .main {
//         padding: 0 3.625rem;
//         padding-top: 1.563rem;
//     }

//     // @media (max-width: 1024px) {
//     //     font-size: 1vw;
//     // }
// }

body {
    background-color: #F6F5F8;
}

.btn {
    font-weight: 600;
}


// aside
.fixed-aside {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow: scroll;
    padding-right: 30px;
    padding-top: 1.263rem;
}

// main 
.main {
    padding-top: 1.563rem;

    &.close {
        margin-left: 0;
    }
}




.aside-top-bar {
    text-align: right;
    background-color: $blue;
    border-radius: 0 1.25rem 0 0;
    padding: 0.75rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;


    .aside-title {
        padding-left: 1.125rem;

        h5 {
            font-size: 1.313rem;
            font-weight: 700;
            margin-bottom: 0;
            color: #fff;
        }
    }
}

.mb-3 {
    margin-bottom: 0 !important;
}

// style for title 
.title-container {
    .title {
        h5 {
            font-size: 1.313rem;
            font-weight: 700;
            margin-bottom: 0;
        }
    }

    button {
        border: 0;
        outline: none;
        background-color: #fff;

        &:hover {
            background-color: #fff;
        }
    }
}


.data-grid {
    border-collapse: collapse;


    .thead {
        .th {
            background-color: #FAF9F9;
            text-transform: uppercase;
            text-align: center;
            padding: 0.7rem 0.5rem;

            &:first-child {
                border-radius: 10px 0 0 10px;
            }

            &:last-child {
                border-radius: 0 10px 10px 0;
            }
        }
    }



    .tbody {
        tr .td {
            padding: 0.7rem 0.8rem;
            border-bottom: 2px solid #FAF9F9;
        }

        tr:last-child .td {
            border-bottom: none;
        }
    }
}