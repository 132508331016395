.print {

    .fixed-aside {
        position: static;
        width: calc(100% - 2.625rem) !important;
        padding: 0 2.625rem;


        .tab-container {
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;

            .table-wrapper {
                width: calc(25% - 1.625rem);
                margin-right: 1.625rem;
                margin-top: 1.068rem;
            }
        }
    }

    .aside-top-bar {
        display: none;
    }

    main {
        margin-left: 0 !important;
        width: 100% !important;

        .btn-download {
            display: none;
        }
    }

        .title-container>.btn-wrapper {
            display: none;
        }
}