.filter-fields {

    .title {
        font-size: 1.188rem;
        font-family: $font-family-base;
        font-weight: 700;
    }

    .select-container {
        position: relative;
        background-color: #fff;
        border: 1px solid #5464B0;
        border-radius: 0.313rem;
        border: 0.063rem solid #E7E7F1;
        box-shadow: 0 0 0.25rem #D8D8D8;

        &.open {
            border-color: #5464B0;

            button {
                color: #5464B0;
                font-weight: 700;
                font-style: normal;
            }
        }

        .select-button-wrapper {
            position: relative;

            button {
                padding: 0.625rem 1rem;
            }

            &::after {
                content: "";
                width: 2.5rem;
                height: 2.5rem;
                background: url('../../icons/chevron_down.svg') no-repeat center/cover;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }



        button {
            width: 100%;
            text-align: left;
            background-color: transparent;
            border: 0;
            color: #000;
            font-size: 1rem;
            font-weight: 400;
            font-style: italic;
            outline: none;
            box-shadow: none;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            height: 150px;
            overflow: auto;
            margin-bottom: 0;
            padding: 0.625rem 1rem;
            // position: absolute;
            width: 100.2%;
            top: 94%;
            background-color: #fff;
            z-index: 10100;
            border: 0.063rem solid #5464B0;
            box-shadow: 0 0.313rem 0.25rem #D8D8D8;
            border-top: 0;
            border-bottom-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
            position: absolute;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                padding: 0.75rem 0;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #D8D8D8;
                }
            }
        }


    }

    // select {
    //     appearance: none;
    //     width: 100%;
    //     padding: 0.625rem 1rem;
    //     border-radius: 0.313rem;
    //     border: 0.063rem solid #E7E7F1;
    //     box-shadow: 0 0 0.25rem #D8D8D8;
    //     font-size: 1.063rem;
    //     font-family: $font-family-base;
    //     font-style: italic;


    // }

}