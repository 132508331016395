.dashboard {
    .fixed-aside {
        // width: 31.5rem !important;
        top: 4rem;
    }

    .search-filter-container {
        display: flex;
        justify-content: space-between;

        .cohorts-filter {


            .filter-fields {
                width: 100%;

                .select-container {
                    .select-button-wrapper {
                        button {
                            padding: 0.9rem 1rem;
                            font-size: 1.188rem;
                            font-style: normal;
                        }
                    }
                }
            }

        }

        .cohorts-filter,
        .search-filter {
            display: flex;
            flex: 0.46;
        }

        .search-filter {
            // flex-basis: 43.5%;
            position: relative;

            &::before {
                content: '';
                width: 1rem;
                height: 1rem;
                display: inline-block;
                background: url('../../icons/search-icon.png') no-repeat center/cover;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 1.3rem;
            }

            input {
                width: 100%;
                border: none;
                display: inline-block;
                font-size: 1.188rem;
                padding: 0.9rem 1rem;
                padding-left: 2.5rem;
                box-shadow: none;
                outline: none;
                border: 1px solid #E7E7F1;
                box-shadow: 0 0 4px #D8D8D8;
                font-family: $font-family-base, Arial, Helvetica, sans-serif;
                font-weight: 500;
                color: #000;
                border-radius: 0.313rem;
            }
        }
    }

    .filter-content {
        margin-top: 1.5rem;
        border-radius: 15px;
        overflow: hidden;

        .content {
            background-color: #fff;
        }
    }

    .filter-header {
        background-color: #5464B0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.9rem;

        @media (max-width: 1220px) {
            flex-wrap: wrap;
        }

        h3 {
            flex-basis: 20%;
            font-size: 1.313rem;
            color: #fff;
            font-weight: 700;
            font-family: $font-family-base, Arial, Helvetica, sans-serif;
            margin-bottom: 0;

            @media (max-width: 1220px) {
                flex-basis: 30%;
                margin-bottom: 1rem;
            }

            span {
                font-weight: 400;
            }
        }

        .select-container {
            display: flex;
            justify-content: flex-start;
            flex-basis: 78%;
        }

        .button-list {
            flex-basis: 10%;

            button {
                border: 0.125rem solid #fff;
                border-radius: 0.625rem;
                font-size: 1rem;
                font-weight: 700;
                background-color: #fff;
                color: #5464B0;

            }

        }
    }

    .map {
        width: 10.063rem;
        padding: 0.75rem 0 0.75rem 28px;
        background-color: #5464B0;
        position: fixed;
        right: 0;
        bottom: 6.75rem;
        border-top-left-radius: 1.563rem;
        border-bottom-left-radius: 1.563rem;
        cursor: pointer;

        img {
            width: 2.5rem;
            height: 2.313rem;
        }


    }



}